import React, { useState, useEffect, useRef } from 'react';

import {

  Routes,
  Route,
  Switch,
  Link,
  useLocation
} from "react-router-dom";

import { AnimatePresence, motion } from 'framer-motion'

import './assets/style.scss';

import { Email } from "react-obfuscate-email";

import {
  Link as LinkUI,
  Offcanvas,
  OffcanvasContainer,
  Section,
} from 'uikit-react';

import useLocalStorage from 'use-local-storage'

import Home from './components/Home'
import Work from './components/Work'
import About from './components/About'
import SingleWork from './components/SingleWork'
import NoMatch from './components/NoMatch'
import Header from "./components/Header";
import SiteFooter from './components/Footer';
import Loading from './components/Loading'

import List from './components/List'

import logo from './assets/images/logo.svg';

const App = () => {
  window.scrollTo(0, 0);
  const location = useLocation();
  const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [theme, setTheme] = useLocalStorage('theme', defaultDark ? 'dark' : 'light');

  const switchTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme)
  }



  return (
    <AnimatePresence
      exitBeforeEnter
      initial={false}
    >
      <div className="everything" data-theme={theme}>
        <OffcanvasContainer>
          <Header />
          <div className="darkmode" onClick={switchTheme}> Switch to {theme === 'light' ? '🌑' : '☀️'}</div>
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Home />} />

            <Route path="*" element={<NoMatch />} />
          </Routes>
          <SiteFooter />
          <Offcanvas id="menu" options="overlay: true" className="">
            <Link to='/'><img className="logo" src={logo} alt="Scott Bamford" width="250" height="76" /></Link>
            <div className="inner-nav">
              <h5>About</h5>
              <p id="header">
                Designer and artist crafting multimedia experiences that combine art direction and strategic value with human centered design philosophies.
              </p>

              <h5>Services</h5>
              <p id="header">
                Graphic and Digital Design, Art Direction, Illustration, Interactive and Experience.                       </p>
              <p>
                <a href="https://www.linkedin.com/in/bamfrd/"> LinkedIn</a>
                <br />
                <Email email="bam@bamford.design">
                  Email
                </Email>
              </p>
            </div>
            <div className="close-buttom-menu">
              <LinkUI toggleOptions="target: #menu;" href="#">
                Close
              </LinkUI>
            </div>
          </Offcanvas>
        </OffcanvasContainer>

      </div>
    </AnimatePresence>
  );
}

export default App;
