import React, { useState, useEffect, useRef } from "react";
import { divnk, useLocation, withRouter } from "react-router-dom";
import { AnimatePresence, motion, AnimateSharedLayout } from "framer-motion";
import { Container, Accordion, AccordionItem } from "uikit-react";
import loading from '../assets/loading.gif';
import workdata from "../assets/data/work.json";

import illustration from "../assets/data/imageList.json";
window.scrollTo(0, 0);




export default function Home() {

	const [isLoading, setLoading] = useState(true);


	return (
		<div className="homepage">
			<div>
				<br />
				{
					// Start Project
				}

				{workdata.map((work, i) => (
					<div className="worklist">
						<Container>
							<div className="uk-grid work-item">
								<div className="uk-width-1-1">
									<h5>{work.title}</h5>
								</div>


								<div className="uk-width-2-3 ">
									<p>{work.intro}</p>
								</div>
								<div className="uk-width-1-3 uk-text-right">
									<p>{work.tags}</p>
								</div>
								<br /><br /><br />
							</div>

							<div data-uk-slider="center: false">
								<div className="uk-slider-items uk-slider-items">

									{work.imageList == null
										? work.imagelist.map((answer, i) => {
											return (

												//  <img className="work-images" id={i} src={"/assets/images/" + answer} />

												answer.includes(".mp4") ?

													<div className="work-images" dangerouslySetInnerHTML={{
														__html: `

							<video className="work-images" loop
							muted
							autoplay
							playsinline
							poster="${loading}">
						  <source src="${"assets/images/" + answer}" type="video/mp4" />
						</video>
						` }}></div>
													:
													<img className="work-images" src={"assets/images/" + answer} alt="Work Image" />


											);
										})
										: ""}

								</div>



							</div>
							<div className="uk-width-1-2@s">


								{
									work.body == "" ? <br /> :
										<Accordion>


											<li>
												<a className="uk-accordion-title" href="#">Read more ...</a>
												<div className="uk-accordion-content">
													<p dangerouslySetInnerHTML={{ __html: work.body }} />

												</div>
											</li>

										</Accordion>

								}







							</div>

						</Container>
					</div>

				))}
				{
					// End Project
				}

			



				<Container>

					<div className="uk-text-center">
						<br />
						<br /><br /><br /><br />
						<br />
						<svg uk-scrollspy="cls:uk-animation-fade" version="1.1" id="exp" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
							viewBox="0 0 1177.1 480" >

							<g id="Layer_1">
								<g>
									<path d="M718.1-403.8c0,21.5-6.2,38.2-18.6,50c-12.4,11.9-27.8,17.8-46.3,17.8c-19.5,0-35.2-5.8-47.1-17.4
			c-11.9-11.6-17.8-26.6-17.8-45v-25.5h34.3v21.8c0,11.7,2.6,20.8,8,27.2c5.3,6.4,12.8,9.6,22.6,9.6c10,0,17.7-2.9,22.8-8.6
			c5.2-5.7,7.7-13.6,7.7-23.7c0-12.8-2.9-24.6-8.8-35.2c-5.9-10.6-15.9-22.3-30.1-35.2c-35.7-31.5-53.6-62-53.6-91.3
			c0-19.3,5.5-35,16.5-47.3c11-12.3,26.6-18.4,46.7-18.4c18.7,0,33.7,5.8,45,17.4c11.3,11.6,17,26.7,17,45.4v26h-33.1v-22.2
			c0-24-9.9-36-29.7-36c-9.2,0-16.2,2.5-20.9,7.5c-4.7,5-7.1,12-7.1,20.9c0,13.4,3.1,25.1,9.2,35c6.1,9.9,17.6,22.5,34.3,37.9
			c16.7,15.1,29.1,29.6,37.1,43.5C714.2-435.5,718.1-420.3,718.1-403.8z"/>
									<path d="M880.6-341h-37.3L801.5-465l-18.8,42.3v81.6h-34.8v-278.9h34.8v140.7l61.5-140.7h36l-56.5,120.2L880.6-341z" />
									<path d="M939.2-341H904v-278.9h35.2V-341z" />
									<path d="M1084.1-341H976.9v-278.9h34.8v246.6h72.4V-341z" />
									<path d="M1215.9-341h-107.2v-278.9h34.8v246.6h72.4V-341z" />
									<path d="M1362.1-403.8c0,21.5-6.2,38.2-18.6,50c-12.4,11.9-27.8,17.8-46.3,17.8c-19.5,0-35.2-5.8-47.1-17.4
			c-11.9-11.6-17.8-26.6-17.8-45v-25.5h34.3v21.8c0,11.7,2.7,20.8,8,27.2c5.3,6.4,12.8,9.6,22.6,9.6c10,0,17.7-2.9,22.8-8.6
			c5.2-5.7,7.7-13.6,7.7-23.7c0-12.8-2.9-24.6-8.8-35.2c-5.9-10.6-15.9-22.3-30.1-35.2c-35.7-31.5-53.6-62-53.6-91.3
			c0-19.3,5.5-35,16.5-47.3c11-12.3,26.6-18.4,46.7-18.4c18.7,0,33.7,5.8,45,17.4c11.3,11.6,17,26.7,17,45.4v26h-33.1v-22.2
			c0-24-9.9-36-29.7-36c-9.2,0-16.2,2.5-20.9,7.5c-4.7,5-7.1,12-7.1,20.9c0,13.4,3.1,25.1,9.2,35c6.1,9.9,17.6,22.5,34.3,37.9
			c16.7,15.1,29.1,29.6,37.1,43.5C1358.1-435.5,1362.1-420.3,1362.1-403.8z"/>
								</g>
								<g>
									<path d="M109.3,287.1H0V8.2h109.3V40H34.8v86.7h54v30.6h-54v98h74.5V287.1z" />
									<path d="M270.7,287.1h-36.8l-36-107.6l-36.4,107.6h-35.2l53.2-140.3L129.6,8.2H166l33.5,105.9L233.8,8.2H269L218.3,143
			L270.7,287.1z"/>
									<path d="M415.2,115c0,18.7-4.8,32.9-14.4,42.5c-9.6,9.6-23.5,14.4-41.7,14.4H326v115.1h-34.8V8.2h67.8c18.1,0,32,4.8,41.7,14.4
			c9.6,9.6,14.4,23.9,14.4,42.9V115z M380,117.1V63.9c0-16.2-6.8-24.3-20.5-24.3H326v102.2h33.5C373.2,141.8,380,133.5,380,117.1z"
									/>
									<path d="M547.4,287.1H438.2V8.2h109.3V40h-74.5v86.7h54v30.6h-54v98h74.5V287.1z" />
									<path d="M661.4,287.1v-94.6c0-18.4-8-27.6-23.9-27.6h-31.4v122.3H571V8.2h68.2c37.1,0,55.7,18.7,55.7,56.1v42.3
			c0,24.8-9.9,39.6-29.7,44.4c10.3,2.2,18.1,7.3,23.2,15.3c5.2,8,7.7,17.4,7.7,28.3v92.5H661.4z M660.2,109.5V63.1
			c0-15.6-7.1-23.4-21.4-23.4h-32.7v97.1h29.3c7.8,0,13.9-2.4,18.2-7.1C658,124.9,660.2,118.2,660.2,109.5z"/>
									<path d="M834.9,40.5h-46.5v246.6h-34.8V40.5h-47.3V8.2h128.5V40.5z" />
									`		<path d="M891,287.1h-35.2V8.2H891V287.1z" />
									`		<path d="M1044.3,224.3c0,21.5-6.2,38.2-18.6,50c-12.4,11.9-27.8,17.8-46.3,17.8c-19.5,0-35.2-5.8-47.1-17.4
			c-11.9-11.6-17.8-26.6-17.8-45v-25.5h34.3v21.8c0,11.7,2.6,20.8,8,27.2c5.3,6.4,12.8,9.6,22.6,9.6c10,0,17.7-2.9,22.8-8.6
			c5.2-5.7,7.7-13.6,7.7-23.7c0-12.8-2.9-24.6-8.8-35.2c-5.9-10.6-15.9-22.3-30.1-35.2c-35.7-31.5-53.6-62-53.6-91.3
			c0-19.3,5.5-35,16.5-47.3c11-12.3,26.6-18.4,46.7-18.4c18.7,0,33.7,5.8,45,17.4c11.3,11.6,17,26.7,17,45.4v26h-33.1V69.8
			c0-24-9.9-36-29.7-36c-9.2,0-16.2,2.5-20.9,7.5c-4.7,5-7.1,12-7.1,20.9c0,13.4,3.1,25.1,9.2,35c6.1,9.9,17.6,22.5,34.3,37.9
			c16.7,15.1,29.1,29.6,37.1,43.5C1040.3,192.6,1044.3,207.8,1044.3,224.3z"/>
									<path d="M1177.1,287.1h-109.3V8.2h109.3V40h-74.5v86.7h54v30.6h-54v98h74.5V287.1z" />
								</g>

								<path id="black_1_" uk-scrollspy="cls:uk-animation-fade" className="st0" pathLength="1" d="M189.5,356.5l771.1,14.3c3,0.1,5.9-0.6,7.7-1.8c3.2-2.1,0.5-4.1-2.2-5.7c-14.3-8.5-32.9-14.9-52.1-20.5
		c-133.6-39.1-299.4-44-450.6-27.2C318.7,331.8,185,366.2,57.7,404.5c-5.2,1.6-3.2,5.5,2.8,5.7l922.7,28.4
		c-4.8-10.2-25.5-16.2-44.7-20.5c-136.6-30.4-294.8-30.9-439.3-11.6c-62.1,8.3-122.5,20.2-186.1,24.9
		c197.9-3.2,396.4,3.3,591.8,19.3c73.6,6,147,13.4,217.4,25.7"/>
							</g>

						</svg>

					</div>

					<div className="skill-container">
						<div className="skill digital-design">
							<div className="skill-title">Graphic & Digital Design

								<div className="skill-background">
									<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
										viewBox="0 0 1000 500">

										<path
											pathLength="0.26"
											id="black_1_" className="st0" d="M95.4,188.3l457.9-24c0,0-380.1,140.6-335.2,93.8s258.6-64.8,322.3-64.8s-127.8,75.8-90.9,47.9
  s198.6-123.7,198.6-123.7l-103.5,20 M561.1,57.4l72.1,10l0.6-4.2l-3.6-0.5l0.6-4.3l-4.9-0.7l0.7-4.9l-4.9-0.7l0.7-5.1l4.4,0.6
  l0.6-4.1l4.9,0.7l0.8-5.8l9.2,1.3l0.6-4.4l9.9,1.4l0.5-4l5.1,0.7l1.6-11.7l-4.6-0.6l0.6-4.7l-9.9-1.4l-0.8,6l-9.6-1.3
  c0,0-0.2,4.8-0.6,4.3s-9.7-1.4-9.7-1.4l-0.7,5.3l-9.7-1.4l2.7-19.8l-4.9-0.7l-0.8,6.1l-4.1-0.6c0,0,0.1,4.4-0.6,4.3
  c-0.7-0.1-5.4-0.8-5.4-0.8l-0.6,4.6l-4.9-0.7l-0.7,4.8l-4.4-0.6l-0.7,4.8l-5.1-0.7l-0.6,4.1l-4.1-0.6l-0.7,5.2l-5.6-0.8l-0.7,4.9
  l-4.3-0.6l-0.6,4.4l-5.8-0.8l-0.8,5.4l-4-0.5l-1.2,8.6L561.1,57.4z"/>
									</svg>

								</div>

							</div>


						</div>






						<div className="art-direction skill">
							<div className="skill-title">Art Direction</div>


							<div className="skill-background">

								<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
									viewBox="0 0 1000 500">

									<path id="blacka" pathLength="1"
										className="st0" d="M106.8,187.9c-0.3-6.8-3.1-12.4-7.9-17.3c-4.7-4.9-11.3-8-18-8.7c-3.5-0.3-7.7,0.5-8.9,3.8
		c-0.7,1.7-0.2,3.7,0.6,5.4c1.8,3.4,3.6,6.2,4.8,9.9c1.1,3.6,2.8,7.7,3.5,11.4 M85.8,231.7c-3.1-10.3-8.7-21.7-15.2-30.2
		c-3.2-4.3-6.9-8.3-11.6-10.9s-10.5-3.6-15.4-1.6c-5,2-8.7,7.3-7.9,12.6c0.8,4.7,4.6,8.2,8,11.5c9.6,9.6,17,21.4,21.4,34.2
		 M79.7,269.9c7.7-1.2,14.8-5.2,20-11c5.1-5.8,8.2-13.4,8.6-21.2c0.1-3.2-0.3-6.6-2.4-9c-3-3.4-8.4-3.6-12.5-1.7s-7.1,5.6-10,9.1
		c-2.9,3.5-5.9,7.1-10,9.1c-3,1.5-6.5,1.9-9.6,3.4c-5.3,2.6-8.8,8-9.7,13.8c-0.9,5.8,0.6,11.8,3.4,16.9s6.9,9.5,11.4,13.3
		c9,7.8,20,13.7,31.8,15.5c11.8,1.8,24.5-1,33.6-8.7 M92.9,264.7c-3.6,2.6-6.2,6.6-7.3,10.9 M124.4,278.4c0.1-5.9-2.6-11.9-7.1-15.7
		c-4.6-3.8-10.9-5.4-16.7-4.3 M106.8,309.9c-0.2,4.3,3.5,8.1,7.6,9.3c4.2,1.2,8.7,0.2,12.8-1.2c8.4-2.9,16.2-8,22.2-14.5
		c3.2-3.4,6-7.4,6.9-12s-0.4-9.8-4.2-12.6 M122.9,320.4c4.5,6,8.9,12,13.4,18.1c0.8,1.1,3.6,4.3,7.3,8.6l2-0.3
		c13.9-2.4,26-11.2,32.6-23.7h0 M178.1,323.1c-10.8-14.2-11.2-13.6-22-27.8L178.1,323.1z M146,284.3c5.8-6,9.5-12.2,11-20.4
		c0.8-4.2,1.2-10.3,3.5-14c2.2-3.6,5.6-6,9.7-7c4.9-1.2,10.1-0.2,15-1.9c4.6-1.6,7.9-6,8-11c0-7.8-7.6-13.2-14.5-14.9
		c-1.4-0.3-2.9-0.5-4.4-0.5c-8.2,0.1-15.9,3.8-22.6,8.4c-6.4,4.4-12.3,9.7-18.7,14c-0.6,0.4-1.3,0.8-2,1c-0.6,0.2-1.2,0.4-1.8,0.5
		c-6.8,0.9-11.1-3.9-15.3-9.7c-4.4-6.1-6.1-13.7-6.6-21.2 M82.5,220.6c12.2-5,23.7-11.9,33.9-20.2c1.6-1.3,3.2-2.6,4.1-4.4
		s1.3-4.1,0.2-5.8c-1.6-2.6-5.2-2.7-8.3-2.5c-15.3,1.2-30.4,4.2-45,9 M82.7,192.9c-2.6,4.2-3.1,9.6-1.2,14.1c1.8,4.6,5.4,8,10.2,9.1
		 M85.1,226.4c1.1-1.4,2-5,1.7-6.8 M887.4,290c0,1.7-6,14.4-10.5,19.7c-4.5,5.3-20.6,9.6-29.3,0.8c-8.6-8.8-7-17.4-3.8-19.1
		c3.8-2.1,16.5-6.7,18.8-8.3c2.3-1.7,0.3-8,6.7-15.1c6.3-7.1,17.4-3.6,22.3,0.2 M865.9,277.8c0,0-7.3-2-24.1-30.9
		c-5.3-9.1-10.2-22-8.4-33c1.3-8.2,8.6-14.8,16.8-12.6c4.9,1.3,6.2,5.7,8.2,10c4.4,9.2,11.4,30.6,19.7,36.6 M849.2,312
		c0,0-21.3,4.3-24.2-15.2c-7.8-52.9,32.2-24.9,32.2-24.9 M882.4,319.9c-5.5-5.9-4.2-11.8-4.2-11.8 M852.5,268.9
		c10.4,7.8,10,14.5,2.8,17.6 M899.4,271.7c-30.8-9.6-23.6-47.1-15.8-65.1c4.9-11.3,10.2-17.6,18.8-16.8c9.6,1,9.2,8.6,8.3,16.1
		c-1.5,12.7-2.3,25.7-1.8,38.3c0.4,9.8,2,20.1,8.9,27.5c13.3,14.1,20.5,33.3,9.6,51.7c-9,15.3-28.5,21.6-45.4,20.5
		c-19-1.2-32.1-14.4-38.6-31.6 M929.3,320.2c0,0,10,4.1,7.1,17.3c-3.1,14.5-36.2,26.2-50.2,21.9c-13.6-4.2-10.4-16.4-10.4-16.4
		 M772.6,97.3c7.4,3.3,19.6,0.1,24.8-5.6c30.3-33.3-24.3-84.3-46.1-69.7c-9.4,6.3-11.7,15.8-11.1,25 M762,70.8
		c1.4,1.8,10.5-3.3,11.7-2.6c2.7,1.6,7.7,21.3-1.1,20.8c-23.3-1.4-27.8-34.4-24.4-42.8c2.8-6.9,14.7,4.7,18.7,9
		c0.8,0.8,0.9,2.1,0.4,3.1C765.2,61.7,760.9,69.4,762,70.8z M715.5,96.1c-3.2-4.9-22.8-35-1-49.7c6.2-4.2,16-3.5,25.8,0.6
		c0.8,12.1,6.8,23.5,8.6,26.3c3.9,5.9,12.6,19,23.7,23.9c-1.7,6.3-5.1,13.1-13.3,16.8C744.1,121,722,106,715.5,96.1z M726.3,94.1
		c1.7,1.6,10-4.7,11.2-4.2c2.9,1.2,10.5,20.1,1.6,20.8c-23.2,1.7-32.1-30.4-29.9-39.2c1.9-7.2,15.2,2.7,19.7,6.4
		c0.9,0.7,1.2,1.9,0.8,3C728.2,84.6,725,92.8,726.3,94.1z M724.8,32c0,0,16.5-42.1,53.5-22.5 M704.4,78.4
		c-35.4-11.3-3.2-47.1,12.3-43.1 M772.9,137.6c0,0,0.3-33.7,28.6-27.9c20.8,4.3,12.4,25.8,12.4,25.8 M637.5,78.3l38.2-9.4
		 M657.1,107.8L680.7,89 M652.9,83.5l37.9-3.7 M188.4,323.1l96.4,22.9L200,334.8l29.9,13.4"/>
									<path id="yellowa" className="st7" pathLength="0.5"
										d="M14.2,206.9c0.9,1.7,2.6,2.9,4.4,3.3 M10,212.6c1.1,1.9,3.3,2.9,5.4,2.5 M15.8,223.1
		c-2-0.6-4.3-0.1-5.7,1.3 M17.9,229c-2.1,0.3-4,1.5-5.2,3.2 M22.4,236.1c-1.8,0.7-3,2.5-3,4.4 M26.9,239.7c-1.8,1.3-2.8,3.5-2.6,5.7
		 M181.7,359.7c5.9-1.6,13.7-4.3,14.5-11.4c0.3-3-1.4-5.9-4.1-7.3c-4.3-2.1-8.8,0.7-12.2,3.2c-3.2-3.2-6.4-6.3-9.6-9.5 M784.3,268.1
		l17.6,1.3l-17,8.8l5.2,20.6l-13.6-14.6l-21.8,4l15.3-15.3l-11-12l18.9,3.5l7.5-12.1L784.3,268.1z M27,306.5l4-23.3l11.4,18.9
		l23.1,1.9l-16.9,16.9l12,19.8l-21.3-13.4l-13.6,17.5l2-21l-14.6-6.2L27,306.5z M961.3,202.7l14,2.1l-10.5,8.1l1.7,11.7l-10-8.6
		l-11.8,9l2.4-14l-8.4-4.5l14.3-4.1l2.8-11.1L961.3,202.7z M880.9,118.7l3.1,19.4l29.5-6.8l-18.5,17.9l11.6,20.8l-25.6-7.5
		c0,0-17.7,13.7-15.4,12c2.4-1.8,2.5-21.5,2.5-21.5l-15.5-12l22.4-6.4L880.9,118.7z M158.8,343.3c2.9,3.4,5.9,6.9,8.8,10.3
		c-0.2-0.2-3.1,4.9-3.3,5.4c-0.8,2.1-1.2,4.4-0.6,6.6c1.2,4.5,6.3,6.6,10.5,4.7c6.2-2.8,6.8-10.5,7.1-16.4"/>
									<path id="bluea"
										pathLength="1"
										className="st6" d="M285,79.6c3-23.2,33.7-29.7,46.9-24.7c8.6,3.3,16.1,9.6,20.2,18 M536.4,418
		c-8.5,35.8-62.5,32.3-70.3,2.6 M552,391.1c2.7,14.1-15.6,35.3-39,31.3 M489.2,406.8c0.5,5.6-26.2,21.6-50.7,12
		s-21.4-33.1-21.4-33.1 M573,405.2c0,0,2.7,13.1,24.8,14.9c1.8,0.2-3.5,11.7-4.4,13.3c-13,22.5-54.6,24.4-64.2-2.2 M623.8,393
		c2.1,6.7,3.1,27.6-26,27.1 M393.5,30.6c6.6-29.8,51.4-27.7,58.4-3.1 M381,53.1c-2.4-11.7,12.5-29.5,31.9-26.5 M432.9,39.2
		c-0.5-4.6,21.4-18.3,41.9-10.7s18.2,27.2,18.2,27.2 M363.4,41.7c0,0-2.4-10.8-20.8-12.1c-1.5-0.1,2.7-9.8,3.5-11.1
		c10.5-18.8,45-21,53.4,0.9 M321.3,52.6c-1.9-5.5-2.9-22.9,21.2-22.9 M429.9,412.2c-8.5,35.8-62.5,32.3-70.3,2.6 M382.7,400.9
		c0.5,5.6-26.2,21.6-50.7,12s-21.4-33.1-21.4-33.1 M153,104.5c-2.4-33.4,38.9-51.3,58.9-48c13.1,2.1,25.4,8.9,33.5,19.5 M269.8,54.2
		c0,0-0.2-15.9-25.7-23c-2.1-0.6,6.6-12.9,8.1-14.6c20.2-23.4,69.2-16.4,74.5,16.9 M207.7,57.1c-1-8.3,2.5-33,36.4-25.9
		 M743.7,351.4c-7.6-6.6-12.1-19.2-6.2-28.3c6.4-9.9,17.9-7.8,26.9-3.2 M89.2,141.4c0,0,127.6-13.6,113.4,86.4 M112.5,152.2
		c3.2,0,73.4,18.2,62.6,112.5 M32.4,137.5l24.4,5.8 M32.4,158.4l35.2-8.7 M817.4,230.5c-18.3-40.9,19.6-44.2,23.3-44.6 M672.7,37.1
		l10.3,16.2l-72.6-7.5 M976.1,421.1c-0.8-1.3-122.2-156-73.6-129c48.6,26.9,71.8,125.4,51.9,119.4c-20-6-59.9-79.8-59.5-81.1"/>
									<path id="reda"
										pathLength="1"
										className="st5" d="M35.6,205.9c-4.5,1.4-10,3.2-12.4,7.7c-4.8,8.9,4.1,23.4,14.3,22.7c6.8-0.5,12.9-4,19.4-6
		 M787.2,145.5c-0.9-5.8-0.9-10.4-0.4-14.1c1.3-9.1,10.4-16.6,19.7-14.3c2.8,0.7,5.4,2.3,6.8,4.8c2.6,4.3,1.3,9.2,0.6,13.7
		c-0.8,5,0.1,10,0.8,14.9c1.5,10.2,2.1,20.6-8.3,26.2c-16.2,8.7-34.8-5.3-36.9-22.1c-1-8,1.3-18.4,9.2-22.2c3.2-1.5,5.8,0.4,7.7,2.8
		 M780.9,397.4c18.6-39.2,107.7-4,122.4,10.6c14.6,14.6-37.9,14.6-80.5-7.4s-45.9-106.5-45.9-106.5 M976.1,378.7
		c-14.3-29.9-24.4-119-14-123.7c10.4-4.7,3,84.1-6.7,51s0-71,0-71 M894.9,124.5c0,0,68.6-114,90.5-106c12.4,4.6-34.5,64-45.3,66.2
		c-15.6,3.2-16.5-49.1,5-60.4 M65.9,327l42.6,29.4l-51.6-66.3l57.3,51.7"/>
								</svg>

							</div>





						</div>




						<div className="skill illustration"

						>

							<div className="skill-background">

								<svg version="1.1" id="ill" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
									viewBox="0 0 1000 500">
									<path id="red" className="st5"
										pathLength="1"
										d="M863.4,179.5c0,0,89-86.1,129.6,224.5l-23.2-2.1l20.8,4.4 M297.1,335.3 M976,409.6l-15.7-0.9
		l13.1,3.9 M834.6,325.9c-11.8-1.3-25.3-9.6-25.3-9.6l-4.3,1.6l-2.2-3.3c0,0-3.2,1.8-4.8,2.8 M800.3,320.9c0,0,7.1,16.5,17.5,12.7
		s2.3-10.4-8.4-11.8s-8.4,2.8,5.8,4.4 M879.3,309.6c3.5,5.1,12,7.3,23.3-2.4 M33.9,212.3c0,0,16.4,56.8,51.3,53.8
		c31.9-2.7,35.7-65.1,35.7-65.1 M228.5,400.5l-114.2,18.8l2.6,26.2l107.9-5L213,318.2c0,0-104.6,10.3-106.2,10.7 M154.3,407.5
		c0,0.7,3.1,23.6,3.5,22.7c0.4-0.9,14-22.7,14-22.7s18.1,20.8,18.6,19.7c0.4-1.1,0-26.9,0-26.9l-45.7,5.9c0,0-0.7,3.5,0.9,3.3
		c1.5-0.2,49-7,49-7 M65.3,381c-3.5,1.9-8.4,24.8,3.8,39.7s19.5-17.5,13.4-29.7c-6.1-12.2-14.6-3.2-21.3,0 M255.5,412.7
		c3.9-0.7,15.9-20.9,9-38.9c-6.9-18-24,10.4-22.1,23.9c1.9,13.5,12.8,7.6,20.2,6.7 M608.4,446.2c3.7-22.8-13.8-45.4-32.6-42.1
		c-18.8,3.3-16.3,29.1-8.5,35.1 M181.7,115.9c0,0-15.3,12.1-17.7,16.6c-2.4,4.5,28-11.8,30.1-14c2-2.2-12.5,1.9-12.5,1.9l-43,14.6
		 M276.1,92.4c0.3,7,42.5-3.4,32.3-7c-7.1-2.5-13.4-0.2-16.5,1.4c-0.6,0.3-0.4,1.2,0.3,1.2c9.7,0.1,45,1.8,45,1.8 M296.5,63.7
		c4.3-12.3,5.4-32.6,1.9-36.5c-3.6-3.9-6.5,14.1-6.5,14.1c-6.2,25.6,10-1.2,12.2-20.3c2.2-19.1-25.4,10.1-33.3,23.7 M183.9,65
		c-7.9-13.6-35.5-42.8-33.3-23.7s18.4,45.9,12.2,20.3c0,0-2.9-18-6.5-14.1s-2.4,24.2,1.9,36.5 M61.1,48.2C65,45,38.7,36.8,39.2,49.2
		c0.5,12.4,16.3,12.2,15.7,5c-0.6-7.2-2,9.6,4.8,20.9c6.9,11.3,7.7-3.3,2-10.2c-5.6-6.9,5,9.1,16.4,16.5c11.4,7.4,1.4-20.2-8.4-21.6
		s6.2,5,25-0.9s-13.4-19.5-19.4-8.2s5.3-1.6,11-17.2S69,36,66.6,46.2s4.9,0.8,1.6-16.9c-3.3-17.7-15.5,7-11.9,14.1
		C56.3,43.4,57.2,51.5,61.1,48.2z M281,167.6c3.9-3.3-22.4-11.5-21.9,0.9c0.5,12.4,16.3,12.2,15.7,5c-0.6-7.2-2,9.6,4.8,20.9
		s7.7-3.3,2-10.2c-5.6-6.9,5,9.1,16.4,16.5c11.4,7.4,1.4-20.2-8.4-21.6c-9.8-1.4,6.2,5,25-0.9s-13.4-19.5-19.4-8.2
		c-6,11.3,5.3-1.6,11-17.2s-17.4,2.6-19.8,12.8s4.9,0.8,1.6-16.9c-3.3-17.7-15.5,7-11.9,14.1C276.1,162.7,277.1,170.9,281,167.6z
		 M510.6,347.8c-24-16.6-42.1-5.5-37.2-2.3c1.8,1.2,9.1-1.9-1.6-5c-13.9-4-31,10.3-31,10.3 M443.3,352.2c0,0,23.9,36.6,62.8,1
		 M517.8,361.4c-37.8-17.5-78.5-3.3-79.8-3.2 M440.7,451.6c5.3,5.2,54.9,27.1,88.8,11.6 M269.4,333.2c0,0,9.5,32.8,29.6,31.1
		c18.4-1.6,20.6-37.6,20.6-37.6 M98.8,352.2c0.6-1-95.1-8.1-73.5,0.5c21.6,8.5,64.5,11.8,40.9,1.7c-23.6-10.1-58.5-5.8-58.5-5.8"/>
									<path id="blue" className="st6"
										pathLength="1"
										d="M844.2,189.5c55.1,4.6,63.7,27,79.9,50.8c3.7,5.4,13.3,35.8,4.2,25.8c-9.1-10-55.4-47.7-45-41.5
		c10.4,6.2,45.9,33.3,44.6,46.6c-1.3,13.3-66.3-29.9-61.2-36.8c5.1-6.9,63.6,51.9,50.8,60.7c-12.9,8.9-81.1-55.2-77.8-64.5
		c3.3-9.3,75.2,76,67.2,73.4c-8-2.7-64.7-82.1-101.5-70.5c-4.5,1.4-8.8,4.8-11,5.8c-0.6,0.3-1.4,0.1-1.8-0.5
		c-3.4-5.6,5.4-26.5,18.8-33.4c18.8-9.8,49.1-17.4,32-11c-12.7,4.8-43.9,36.7-44.7,31.6c-1.8-11.8,29.9-55.6,92-39.5 M238.9,241.5
		c3.9-3.3-22.4-11.5-21.9,0.9c0.5,12.4,16.3,12.2,15.7,5c-0.6-7.2-2,9.6,4.8,20.9c6.9,11.3,7.7-3.3,2-10.2c-5.6-6.9,5,9.1,16.4,16.5
		c11.4,7.4,1.4-20.2-8.4-21.6c-9.8-1.4,6.2,5,25-0.9s-13.4-19.5-19.4-8.2c-6,11.3,5.3-1.6,11-17.2s-17.4,2.6-19.8,12.8
		c-2.4,10.2,4.9,0.8,1.6-16.9s-15.5,7-11.9,14.1C234.1,236.6,235,244.8,238.9,241.5z M153.1,364.9c0.3-25.8-13.7-31.8-19.9-20.5
		l3.3,5.8l-5.4,0c-0.7,3.1-0.9,6.9-0.6,11.2C132.3,386.5,152.7,399.3,153.1,364.9z M175.8,361c0.3-25.8-13.7-31.8-19.9-20.5l3.3,5.8
		l-5.4,0c-0.7,3.1-0.9,6.9-0.6,11.2C155,382.6,175.4,395.4,175.8,361z M198.5,360.1c0.3-25.8-13.7-31.8-19.9-20.5l3.3,5.8l-5.4,0
		c-0.7,3.1-0.9,6.9-0.6,11.2C177.7,381.6,198.1,394.5,198.5,360.1z M160.2,411.5c2.4,4.9,5.7,10.6,10.4,7.8s6.7-15.3,1.1-14
		c-5.5,1.3-1.1,29.2,1.2,32.9h7.1l1.5-10.1l-9.5-16.5 M138.2,437.5l-31.3,21.1l10.4,28.8l25.9-1.2c0,0-24.8-28.3-23.9-28.8
		c0.9-0.6,13.4-10.5,13.4-10.5 M199.6,444.1l25.2,32l28.1-21.8l-2.3-24.2l-24.8,30l-29.1-28.3 M577.9,403.9
		c0.3-8-34.9-45.7-46.2,6.3s32.6,103.6,46.7,82c14.1-21.6,9.5-43.3,8.8-44.3 M657.1,436.6c14.1-6.2,8.9-21.8-3.5-20.1 M669.8,414.5
		c-2.3,2.8-7.5,10.6-0.8,23.6 M732.3,452.6c-0.6,1.1-4.3,23.9,17.5,37.7c24.8,15.6,55.6-29.7,39.1-80.3c-16.5-50.6-53-10-51.8-2.1
		 M760,447.2c7.2-6.7-1.6-37.8-20.7-39.2c-19-1.4-28.7,18.3-18.7,36.9 M724.6,412.3c-5.8-22.5-22.4-23.6-32-10.6 M732.5,467.3
		c-2.7,5.2-17.7,22.4-29.7,13.3c-3.7-2.8-6.8-4.9-9.8-11.9 M230,100.9c-1.9,9.9,6.6,14.9,9.5,14.2s0.9-3.5-3.7-3.5s6,4.4,10.5,4.2
		s8.4-8.2,7.1-10.6c-1.4-2.4-3.1,1.2-1.6,3.4s3.9-6.8,1.1-10.5c-2.8-3.6-19.9,3.4-22.8,5.7 M221.6,129.4c0,0,22.3-1.3,25.6-7.2
		c3.3-5.8-4-18.3-3.3-10c0.9,11.2,25.9,10.6,36.1-2.9 M428.8,155.7c-20,13.3-45.4,98.9,1.8,65.5s43.1-79.9,22.5-47.4
		c-20.6,32.6-14.8,46.1-3.1,51.4c27.5,12.4,55.8-60,51.8-69.9c-4-9.9-63.2,57.1-18.6,60.6s52.5-53.4,48.6-60.1s-33.3,47.2-21.9,58.6
		s61.2-26.8,61.2-45.9 M645,397.4c-9.4-31.9-15.3-52.1-5-82.1c35.9-104.8-17.9-178.1-48.4-209.1c-11.1-11.3-30.5-7.6-36.2,7.2
		c-1.6,4.1-1.1,6.9,3.4,6c12.6-2.3,14.2-29.9-8.8-39.2c-8.6-3.4-278.6-94.1-186.1,282.9c10.8,44.1-4.7,90.6-36.8,122.7l0,0
		 M460.8,242.2h31.3v-3.3h-31.3 M259.8,288.7l74.7,11 M297.1,409.5c0,0,33.5,7.6,36.1-14.6c2.7-22.1-21.1,9.1-22.4,31.1
		c-1.3,21.9,9.8,36.6,9.8,36.6"/>
									<path id="yellow" className="st7"
										pathLength="0.33"
										d="M955.2,273.3c1.3,21,4.2,119.7,1,133.8c-3.2,14.1-17-145.7-16.1-149s28.1,164.1,24.6,168.9
		c-3.5,4.8-26.9,1.2-26.9,1.2l-14.3-224.8c13.3,5,11.6,5.7,17,27.6C945.7,253,976,409.6,976,409.6l-41.6-190.5 M897.6,388.9
		c3.1,6.3,70.4,119.4,68.1,95.1c-2.3-24.3-36.9-68.2-88.8-89.8s-85.6,86.5-85.6,86.5 M136.7,186.9c-20.4-126.6-142.1-62.3-118,36.5
		C42.9,323,156.9,311.6,136.7,186.9z M115.8,323.2c-2.4,8.3,0.2,94.1,6.1,106c4.7,9.5-5-64.5-9.1-94.1c-1-7.3-1.6-11.9-1.6-11.9
		 M125.7,384.6c-1-1.2-28.3-23.9-31.3-16.9c-2.9,7,15.5,2.3,12.4-1.3c-3.1-3.6-36,12.1-37.8,26.1 M215.7,418.2c5-5.7-6-9.2-4.2-4.7
		c1.8,4.4,38-0.1,44.1-12.8 M628.5,458c-2.3,8.1-5.3,14.8-8.2,18.8c-8.6,12.3-28.6-9.5-31.8-14.4 M232,50.2
		c31.6-24.6,10.6-35.4-2.1-30.9c-12.7,4.5-31.2,39.3,8.9,11.6s-26.1-1.5-52.8,14.4 M328.8,318.5c-11.8-73.2-82.1-36-68.2,21.1
		C274.7,397.2,340.5,390.6,328.8,318.5z"/>
									<path id="black" className="st8"
										pathLength="1"
										d="M818.1,262.7c0,0,11.1-17.1,36.7,6.4 M846.9,272.5c-9.6-14.4-21.9-5.3-21.9-5.3 M803.6,262.2
		c-2.9-5.4-11.4-2.9-11.6-2.5 M887.3,318.7c-3.5,2.3-10.8,18.2-0.7,21.4c10.1,3.2,15.1-11.6,8.7-15.2c-6.3-3.5-15.3-3.5-15.5,3.5
		 M789.3,254.4c4.2-0.7,19.7-0.2,16.8,16.6s-5.1,23-5.7,25.7c-0.7,2.7-1.3,7.3,4,8.6c5.3,1.3,11.7,3,12.5,0.1c0.4-1.5-7-4.3-6.1-1.7
		c1.2,3.5,2.3,4.7,6.1,4.7s8.5-6.2,4.5-10.4 M801.5,262.8c-2.7-1.3-5.3,4.9-4.4,8.6s9.1-0.4,7.8-3.1c-1.3-2.7-9.5-4.7-12.9-3.3
		 M831.4,269c0.1,11.8,9.4,13,10.6,7.1c1.4-6.9-17.3-4.5-18.8-3.2 M788.2,287.4c-1.8,8.2,5.5,24.4,9.8,44.6
		c4.2,20.2,16.8,18,26.8,18.8c10,0.9,46.1-15.7,48.6-16.7 M825,372.9c0,0,168.6-34.9,95.4-32.3s-89.8,52.2-89.8,52.2l32.7-23.9
		c0,0-41.1,9.6-45.2,47.2 M42.9,166l3.3,34.9 M93.8,154.3l5,39.9 M238.4,246.7c-0.9,1.4-0.8,5,1.5,5.3c2.3,0.2,5.1-2.7,4.5-4.5
		C243.7,245.7,240.7,243.1,238.4,246.7z M141.8,390.2c0,0-6-1.2-8.1,7.8 M137.7,394.1c0,0,37.9,12.5,59.1-6.1 M200.5,394.1
		c2.7-7.7-4.4-10.3-8.1-9.4 M106.8,453l13.1,29.9 M219.3,475.1c0.9-0.7,28.8-22.1,28.8-22.1 M153.2,365.4
		c-2.8-16.2-22.6-15-15.4,6.5c7.2,21.6,13.4,7.2,13.4,7.2 M175.8,361c0-12.6-14.3-16.3-15.6-4.4c-1.3,11.9,4.4,29.4,11.2,23.2
		 M198.5,356.3c-0.4-3.3-5.6-8.9-10.2-4.8s-7.4,27.4,5,28.1 M788.3,379c-27.3-28.2-48.4,1.5-51.3,4.2 M163.9,108.7
		c0,0,24.5,8.9,28.1,2.8c2.2-3.7-25.2-3.2-19.3,1.8 M186.2,129.4l-22.3,22.3 M308.4,64.4c0,0-27.4,7.9-26,15.3
		c0.7,3.4,17.3-6.3,8.5-6.5c-8.8-0.2-11.3,5.1-12.3,5.5 M289.7,97.5l37.2,9.1 M219.4,85.7c0,0-5.3-1.8-10.9,1.4s5.8,9,10.1,6.5
		c4.3-2.5-9.6-13.5-19.8-15.1s-8.5,15.8-7.6,19 M252.7,58c-4.3-0.2-12.7,13.6-3.6,21.6c9.1,8,14.6,0.3,9.4-2.5
		c-5.2-2.8-14.8,3-14.6,5 M60.5,53.5c-0.9,1.4-0.8,5,1.5,5.3c2.3,0.2,5.1-2.7,4.5-4.5C65.8,52.5,62.9,49.9,60.5,53.5z M280.4,172.9
		c-0.9,1.4-0.8,5,1.5,5.3c2.3,0.2,5.1-2.7,4.5-4.5C285.7,171.8,282.8,169.3,280.4,172.9z M794.4,285.6c11.5,0,10.5-21.9,10.5-21.9
		l-37.3-9.4C765.4,271.6,783,285.6,794.4,285.6z M812.2,273l-8.6-1.8 M860.8,278c0.8-2.2,1.1-3.7,1.1-3.7l-49.7-7.7
		c-3.8,21.8,14.6,24.1,20.4,25.6C851,297.1,858.3,284.6,860.8,278z M912,308.5c0.3-1.1-11.5-24.3-42.9-28.5
		c-31.4-4.2-16.6-3.9-8.3-1.9c8.3,1.9,13.6,4.7,14.5,5.6 M460.4,223.8c-10.9-24.7-94-29-77.9,36.3S484.6,279,460.4,223.8z
		 M566.8,223.7c-10.3-18.9-94-29-77.9,36.3C505,325.2,597.1,279.3,566.8,223.7z M389,246.2h67 M494.8,246.2h69.9 M430.3,252.1
		l-10.5-0.3l13.2-1.1l-21.8-0.8l18.8-1l-16.5-1.1l17.6-0.6l-20-1c0,0,1.2,1.8,3.2,3.6c4.2,3.8,11.8,7.7,19.4-3.6 M520,253h14
		l-6.8-0.8l8-1c0,0-16.8-1.6-17.2-1.7c-0.4-0.2,18.8-0.5,18.8-0.5l-14.9-1.4l17-0.3l-20.9-1.1c0,0,12.3,20.6,23.3,0.1 M460.8,304.7
		c5.7,16.4,27.2,14.4,31.3,2.2 M389,262.8c0,0,6.9,143.9,88.8,149.3c63.3,4.1,119.7-107.7,120.9-147.8s-25.4-8.3-22.1,29
		c3.3,37.3,26.9,15.2,30.4,0c3.5-15.2,5-29.2,0-22.2c-5,7-7.3,37.1,0.5,29.1c7.8-8,26.6-52.9,15.3-56.5 M440.7,464.3
		c25.1-31.2,10-60,10-60 M777.2,334.2l-20.6,1.8 M782.8,335.1l-20.3,15.8 M583.3,354.9l31.6,20.1 M574.3,354.9l14.2,20.1 M521.2,445
		c0,1.1-2.4,35.9,16.4,45 M689,379.8c-7.8-28-37.1-18.4-41.7-9.1 M277.3,302.7l2,20.1 M298.9,299.7l3.3,23.1 M296,383.9
		c3,5.4,10.2,5.4,8.6,0.4c-1.7-5-9.8-0.1-11.2,7.5 M246.8,388.4c-0.4-0.2-12-7.2-13.9-3c-3.1,6.9,13.6,9.4,13.6,9.4 M260.3,400.7
		l25.9,8.3l0.8-1.7l1.2,0.1l-0.7,2l5.3-0.6c0,0-6.3-4.7-6.9-4.8c-0.6-0.1-22.6-9.1-22.9-9.2"/>
									<path id="green" className="st9"
										pathLength="1"
										d="M66.6,71.2c0,0,5.5,23.1,0,33.1 M120.8,253.1c9.9,19,35.2,54.7,38.5,37.2
		c3.3-17.5-1-116.6-11.6-84.4c-10.7,32.2,19,93.1,29.3,85.8c10.3-7.3,23.6-161.9,11.6-117.4s-5.3,124.4,9.6,104.8
		c15-19.6,17.3-92.1,12-61.2c-5.3,30.9-8.3,84.4,10,77.1 M244.4,297.6c5.5-10,0-33.1,0-33.1 M630.8,412.2c-0.7-3.2-1.7-6.3-3-9.2
		c-9.4-22.1-32.7-24.1-37,3.9 M703.3,422.1c-23.5-66.9-81.8-17.6-80.8,14.4C624.5,498,726.2,487.6,703.3,422.1z M286.5,223.7
		c5.5-10,0-33.1,0-33.1 M453.2,410.8L327,493.4l90.7-6"/>
								</svg>
							</div>
							<div className="skill-title">Illustration</div>



						</div>






						<div className="ui-ux skill">
							<div className="skill-title">Interactive & Experience </div>

							<div className="skill-background">

								<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
									viewBox="0 0 1000 500">

									<path className="st7"
										pathLength="0.5"
										d="M810.3,397.1v-6.2h6.9V384h7.4v-7.6h6.3h6.9h13.8v6.9h14h7v7.1h6.8v6.7h7.2v7.2h6.7v6.7h6v34.3
		h-6.2v13.7h-6.7v6.9h-7.1v6.7h-6.9v7h-13.8v7h-27.2v-7.2h-14.1V473h-6.9v-6.7h7v-7.1h7.1v-6.6h6.6v-7.1h6.6v-7.2h7.1v-13.7h-7v-7
		h-6.9V411h-6.5v-7h-7.1v-6.6L810.3,397.1z M907.3,23.1c-13,0-61.5,11-51.9,63.5s56.5,43.9,61.2,43.2s45.9-17,44.2-52.9
		C959.1,41.1,929.6,23.1,907.3,23.1z"/>
									<path pathLength="0.34" className="st0" d="M144.33,288.05s493.83-38.91,550.69-12S220.15,340.93,248.08,325s510.79-87.83,615.54-58.38m-5.18,137.9h13.08v13.2H858.44Zm-179.79,30v17.74H660V434.52Zm76-7.55v17.74h-18V427ZM626,439v8.87h-8.86V439ZM940.19,58.37c-.22,43.67-43.23,50.54-44.78,51m-9.85-51c2,2.66,7.31,16.18,6.87,20.39m18.28-30.15c.44,1.33,6.65,17.07,5.32,22.39m-57,75.16H827.13V19.79h27m114.7-4.65h17.29V148.82H960.86" />
								</svg>

							</div>
						</div>

						<div className="identity skill"

						>



						</div>



					</div>

					<br />



				</Container>


				<div className="homepage-spacer">
					<br />
					<br />
					<br />
					<br />

				</div>

			</div>


		</div>

	);
}
